
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as envOoqn9iUI1YMeta } from "/root/injective/injective-bridge/pages/env.vue?macro=true";
import { default as external_45linko06IYNksviMeta } from "/root/injective/injective-bridge/pages/external-link.vue?macro=true";
import { default as fiatc2gtaHKz3OMeta } from "/root/injective/injective-bridge/pages/index/fiat.vue?macro=true";
import { default as indexqL0iPhKuh4Meta } from "/root/injective/injective-bridge/pages/index/index.vue?macro=true";
import { default as wormhole5kvKG2toZzMeta } from "/root/injective/injective-bridge/pages/index/wormhole.vue?macro=true";
import { default as index0nbpxFRgRxMeta } from "/root/injective/injective-bridge/pages/index.vue?macro=true";
import { default as maintenancemiKWOsLPP8Meta } from "/root/injective/injective-bridge/pages/maintenance.vue?macro=true";
import { default as memes_45bridge8TDa5n2BVDMeta } from "/root/injective/injective-bridge/pages/memes-bridge.vue?macro=true";
import { default as transferMcetEaMTEXMeta } from "/root/injective/injective-bridge/pages/transfer.vue?macro=true";
import { default as whr48Jv0V7qyMeta } from "/root/injective/injective-bridge/pages/wh.vue?macro=true";
import { default as wormhole_45migrationfgdNxur4NJMeta } from "/root/injective/injective-bridge/pages/wormhole-migration.vue?macro=true";
export default [
  {
    name: "env",
    path: "/env",
    meta: envOoqn9iUI1YMeta || {},
    component: () => import("/root/injective/injective-bridge/pages/env.vue")
  },
  {
    name: "external-link",
    path: "/external-link",
    meta: external_45linko06IYNksviMeta || {},
    component: () => import("/root/injective/injective-bridge/pages/external-link.vue")
  },
  {
    name: index0nbpxFRgRxMeta?.name,
    path: "/",
    component: () => import("/root/injective/injective-bridge/pages/index.vue"),
    children: [
  {
    name: "index-fiat",
    path: "fiat",
    meta: fiatc2gtaHKz3OMeta || {},
    component: () => import("/root/injective/injective-bridge/pages/index/fiat.vue")
  },
  {
    name: "index",
    path: "",
    component: () => import("/root/injective/injective-bridge/pages/index/index.vue")
  },
  {
    name: "index-wormhole",
    path: "wormhole",
    component: () => import("/root/injective/injective-bridge/pages/index/wormhole.vue")
  }
]
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenancemiKWOsLPP8Meta || {},
    component: () => import("/root/injective/injective-bridge/pages/maintenance.vue")
  },
  {
    name: "memes-bridge",
    path: "/memes-bridge",
    component: () => import("/root/injective/injective-bridge/pages/memes-bridge.vue")
  },
  {
    name: "transfer",
    path: "/transfer",
    component: () => import("/root/injective/injective-bridge/pages/transfer.vue")
  },
  {
    name: "wh",
    path: "/wh",
    meta: whr48Jv0V7qyMeta || {},
    component: () => import("/root/injective/injective-bridge/pages/wh.vue")
  },
  {
    name: "wormhole-migration",
    path: "/wormhole-migration",
    component: () => import("/root/injective/injective-bridge/pages/wormhole-migration.vue")
  }
]